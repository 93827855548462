import { WebsitesProps } from './contracts/websites-props';
import { Project } from '../shared/project/project';
import './websites.css';
import stringLogo from '../shared/assets/string-generator/dark.png';
import iotLogo from '../shared/assets/iot-web/iot.png';
import spaceLauncehs from '../shared/assets/space-launches/launches.png';
import spaceLauncehsReact from '../shared/assets/space-launches/react/dark.png';

export function Websites(props: WebsitesProps) {
  return (
    <div className='card w-full'>
      <div className='card-title websites-title'>Websites</div>
      <div className='projects-card-container w-full'>
        <Project
          id='random-string-generator'
          title='Random String Generator'
          description='A website for generating randomized strings'
          logo={stringLogo}
          link='https://string-generator.gardnernick.com/'
        ></Project>
        <Project
          id='iot-portal'
          title='Iot Portal'
          description='A portal style website for interacting with various IOT projects'
          logo={iotLogo}
          link='https://iot.gardnernick.com'
        ></Project>
        <Project
          id='space-launches'
          title='Space Launches'
          description='A website that displays space launches for NASA and SpaceX'
          logo={spaceLauncehs}
          link='https://space-launches.com'
        ></Project>
        <Project
          id='space-launches-react'
          title='Space Launches (React)'
          description='A website that displays space launches for NASA and SpaceX. This version was created using react instead of angular. I wanted to see how the 2 frameworks compare'
          logo={spaceLauncehsReact}
          link='https://react.space-launches.com'
        ></Project>
      </div>
    </div>
  );
}
