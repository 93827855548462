import { TaskbarProps } from './contracts/taskbar-props';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
// import { MdEmail } from "react-icons/md";
import nglogo from '../shared/assets/ng_transparent.png';
import './taskbar.css';


export function Taskbar(props: TaskbarProps) {
    const mobile = window.innerWidth < 480;
    return (
        <div className='navbar bg-base-100 nav-container'>
            <img src={nglogo} className='logo' alt='logo'></img>
            {/* <ThemeControl></ThemeControl> */}
            <div className='connect-buttons'>
                <button className='btn bg-base-100 btn-sm connect-button' data-theme='dark' onClick={() => window.open('https://www.linkedin.com/in/nicholas-gardner-7b588a105/', '_blank')}>
                    <FaLinkedin/>
                    { mobile ? '' : 'LinkedIn'}
                </button>
                <button className='btn bg-base-100 btn-sm connect-button' data-theme='dark' onClick={() => window.open('https://github.com/gardner-nick', '_blank')}>
                    <FaGithub/>
                    { mobile ? '' : 'GitHub'}
                </button>
                {/* <button className='btn bg-base-100 btn-sm connect-button' data-theme='dark'>
                    <MdEmail/>
                    { mobile ? '' : 'Email'}
                </button> */}
            </div>
        </div>
    );
}