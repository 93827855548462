import { BasicInfoComponentProps } from './contracts/basic-info-props';
import nglogo from '../shared/assets/profile.jpeg'

import './basic-info.css';

export function BasicInfoComponent(props: BasicInfoComponentProps) {
    return (
        <div className='card bg-base-100 image-full shadow-xl basic-info-card'>
            <figure>
                <img src={nglogo} alt='logo'></img>
            </figure>
            <div className='card-body'>
                <h2 className='card-title'>Bio</h2>
                <div className='spacer'></div>
                <p className='bio-info'>
                    Hi, my name is Nick Gardner. Welcome to my site! I am a Full Stack Software Engineer who is passionate about building 
                    reliable cloud based web applications. Let's Connect! You can find my LinkedIn and GitHub in the toolbar.       
                </p>

                <div className='list-container'>
                    <ul className='list-section'>
                        <li>TypeScript</li>
                        <li>.NET</li>
                        <li>JavaScript</li>
                        <li>SQL</li>
                        <li>Terraform</li>
                    </ul>      
                    <ul className='list-section'>
                        <li>Angular</li>
                        <li>React</li>
                        <li>Razor</li>
                        <li>Serverless</li>
                    </ul>     
                    <ul className='list-section'>
                        <li>AWS</li>
                        <li>Azure</li>
                    </ul>                   
                </div>
            </div>
        </div>
    );
}