import { ModalProps } from "./contracts/modal-props";
import "./modal.css";

export function Modal(props: ModalProps) {
  return (
    <dialog id={props.id} className="modal modal-bottom sm:modal-middle">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>
        <h3 className="font-bold text-lg">{props.title}</h3>
        <p className="py-4">{props.mainInfo}</p>
      </div>
    </dialog>
  );
}
