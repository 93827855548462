import { TitleComponentProps } from './contracts/title-component-props';
import './title-component.css'
import nglogo from '../shared/assets/ng_transparent.png';

export function TitleComponet(props: TitleComponentProps) {
    const hidden = window.innerWidth < 480;

    return (
        // <div className='title-component-container'>
        //     <h1 className='title-component-title' >{props.title}</h1>
        //     <h2 className='title-component-sub-title '>{props.subTitle}</h2>
        // </div>
        <div className='avatar-container' hidden={hidden}>
            <div className='avatar'>
                 <div className="avatar-image rounded-full">
                <img src={nglogo} alt='logo'/>
                </div>
            </div>
        </div>
    );
}
